import React, { useState } from "react";
import { Stack, Box, Typography, Modal } from "@mui/material";
import QuantityCell from "./QuantityCell";
import { themeColors } from "../Constant/themeColor";
import { ButtonSquare } from "./Button";
import { themeFonts } from "../Constant/themeFont";

const QuantityModal = (props) => {
  const { show, onSelected, open, setOpen, stockQuantity } = props;

  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [isValidInput, setIsValidInput] = useState(true);

  const handleClose = () => setOpen(false);

  let factor = 0;

  const handleChange = (e) => {
    const { value } = e.target;
    const inputValue = parseInt(value, 10);
    const isValidInput = inputValue % 10 === 0;

    setIsValidInput(isValidInput);
    setSelectedQuantity(value);
  };

  const onQuantityClicked = (quantity) => {
    setSelectedQuantity(quantity);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          bgcolor={`${themeColors.common.pillWhite}`}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "311px",
            p: 2,
          }}
        >
          <Stack p={1} gap={2}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                display: "grid",
                gridAutoRows: "minmax(10px, auto)",
                gridTemplateColumns: "repeat(5, 1fr)",
              }}
            >
              {[...Array(15)].map((item, index) => {
                if (index <= 10) {
                  const label = index * 10;
                  return (
                    <>
                      <QuantityCell
                        onClick={() => {
                          onQuantityClicked(label);
                        }}
                        label={label}
                      />
                    </>
                  );
                } else {
                  factor = factor + 1;
                  const label = index * 10 + 40 * factor;
                  return (
                    <>
                      <QuantityCell
                        onClick={() => {
                          onQuantityClicked(label);
                        }}
                        label={label}
                      />
                    </>
                  );
                }
              })}
            </Stack>
            {stockQuantity < selectedQuantity && (
              <Typography
                fontFamily={themeFonts.regular}
                fontSize={"16px"}
                fontWeight={400}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color="error"
              >
                solo {stockQuantity} quantità disponibili in magazzino
              </Typography>
            )}
            {!isValidInput && (
              <Typography
                fontFamily={themeFonts.regular}
                fontSize={"16px"}
                fontWeight={400}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color="error"
              >
                Non valido! La quantità deve essere multipla di 10
              </Typography>
            )}
            <Stack direction="row" width="100%">
              <input
                placeholder="Scegli una quantità..."
                value={selectedQuantity}
                type="number"
                style={{
                  width: "100%",
                  borderRadius: "4px 0px 0px 4px",
                  padding: "4px 10px",
                  border: "1px solid #E5E8E8",
                  outline: "none",
                  "&:focus-visible": {
                    border: "none",
                    outline: "none",
                  },
                }}
                onChange={handleChange}
              />

              <ButtonSquare
                disabled={
                  stockQuantity < selectedQuantity ||
                  selectedQuantity <= 0 ||
                  !isValidInput
                    ? true
                    : false
                }
                sx={{
                  borderRadius: "0px 4px 4px 0px",
                  width: "30%",
                }}
                onClick={() => {
                  onSelected(selectedQuantity);
                  setOpen(false);
                  setSelectedQuantity(null);
                }}
              >
                OK
              </ButtonSquare>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default QuantityModal;
