import React, { useEffect, useState } from "react";
import {
  Stack,
  Divider,
  styled,
  FormHelperText,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import LabelledInputMaterial from "../components/LabelledInputMaterial";
import Checkbox from "../components/Checkbox";
import TypographyBodySmall from "../components/TypographyBodySmall";
import { themeColors } from "../Constant/themeColor";
import TypographyBodyBase from "../components/TypographyBodyBase";
import LabelledRadio from "../components/LabelledRadio";
import H4 from "../components/H4";
import { ButtonSquare } from "../components/Button";
import NespressoSelect from "../components/NespressoSelect";
import Card from "../components/Card";
import Container from "../components/Container";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import config from "../config";
import { accessToken } from "../Constant/token";
import axios from "axios";
import ProgressIndicator from "../components/ProgressIndicator";
import { baseImageUrl } from "../Constant/constData";
import { themeFonts } from "../Constant/themeFont";
import { Switch, FormControlLabel } from "@mui/material";
import LabelledSwitch from "../components/LabelledSwitch";
import NespressoNazioneSelect from "../components/NespressoNazioneSelect";

const BUSINESS_TYPE_PRIVATE = "privato";
const BUSINESS_TYPE_AGENCY = "azienda";

export const CenterHeading = styled(H4)(({ theme, color }) => ({
  textAlign: "center",
  margin: "24px 0px 16px",
}));

const Checkout = ({ getCartCount }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const storeCode = searchParams.get("store");
  const cartId = localStorage.getItem("cartId");

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [regionId, setRegionId] = useState();

  useEffect(() => {
    getRegionID();
  }, []);

  const initialValues = {
    businessType: BUSINESS_TYPE_PRIVATE,
    businessName: null,
    vatNumber: null,
    firstName: null,
    lastName: null,
    email: null,
    taxId: null,
    sdiopec: null,
    telephone: null,
    address: null,
    civicNumber: null,
    capital: null,
    city: null,
    province: null,
    country: "IT",
    privacyPolicy: false,
    receiveInvoice: false,
    knowOffers: null,
    emailCheck: false,
    phone: false,
    postal: false,
    sms: false,
    selectAll: false,
  };

  const validationSchemaCheckout = Yup.object().shape({
    firstName: Yup.string().required("Nome necessaria *"),
    lastName: Yup.string().required("Cognome necessaria *"),
    email: Yup.string()
      .email("e-mail non valido")
      .required("Email necessaria *"),
    telephone: Yup.string()
      .required("Telefono necessario *")
      .min(10, "Il numero di telefono deve avere almeno 10cifre")
      .max(13, "Il numero di telefono non può avere più di 13 cifre"),
    address: Yup.string().required("Indirizzo necessaria *"),
    civicNumber: Yup.string().required("Civico necessaria"),
    capital: Yup.string().required("Cap necessaria *"),
    city: Yup.string().required("Città necessaria *"),
    province: Yup.string().required("Provincia necessaria *"),
    country: Yup.string().required("Nazione necessaria *"),
    businessType: Yup.string(),
    privacyPolicy: Yup.boolean().oneOf([true], "privacy policy necessary *"),
  });

  const getRegionID = async () => {
    let url =
      config.placeholderApiUrl +
      `/V1/paypalapi/getregionids?country_id=IT
    `;
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios({
        method: "get",
        url,
        headers,
      });
      setRegionId(response.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    const regionID = regionId?.find(
      (o) => o.country_id == values.country
    ).value;
    // console.log("values", values);
    // console.log("values.emailCheck", values.emailCheck);

    const updatedValues = {
      addressInformation: {
        shippingAddress: {
          country_id: values.country,
          street: [`${values.civicNumber},${values.address}`],
          company: `${values.businessName}`,
          telephone: `${values.telephone}`,
          postcode: `${values.capital}`,
          regionId: Number(regionID),
          city: `${values.city}`,
          firstname: `${values.firstName}`,
          lastname: `${values.lastName}`,
          email: values.email,
          sameAsBilling: 1,
        },
        billingAddress: {
          country_id: values.country,
          street: [`${values.civicNumber},${values.address}`],
          company: `${values.businessName}`,
          telephone: `${values.telephone}`,
          postcode: `${values.capital}`,
          regionId: Number(regionID),
          city: `${values.city}`,
          firstname: `${values.firstName}`,
          lastname: `${values.lastName}`,
          email: `${values.email}`,
        },
        shipping_method_code: "flatrate",
        shipping_carrier_code: "flatrate",
        extension_attributes: {
          newsletter_subscribe: values.selectAll ? 1 : 0,
          newsletter_subscribe_email: values.emailCheck ? 1 : 0,
          newsletter_subscribe_phone: values.phone ? 1 : 0,
          newsletter_subscribe_post: values.postal ? 1 : 0,
          newsletter_subscribe_sms: values.sms ? 1 : 0,
          business_type: BUSINESS_TYPE_PRIVATE,
          business_name: `${values.businessName}`,
          vat_number: `${values.vatNumber}`,
          tax_id: `${values.textId}`,
          receive_invoice: 1,
          sdiopec: values.sdiopec,
        },
      },
    };

    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts/${cartId}/shipping-information
    `;
    setSubmitLoading(true);
    // console.log("updatedValues", updatedValues);

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios({
        method: "post",
        url,
        headers,
        data: updatedValues,
      });

      navigate(
        `/proceedCheckout?store=${storeCode}&email=${encodeURIComponent(
          values.email
        )}`
      );
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <>
      {loading && <ProgressIndicator />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaCheckout}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {/* {console.log("errors",  errors)}
              {console.log("values", values)} */}
              <Container>
                <CenterHeading>CHECKOUT</CenterHeading>
                <Card
                  bgcolor="white"
                  sx={{
                    padding: "16px",
                  }}
                >
                  <Stack
                    direction="column"
                    flex="flex"
                    alignItems="start"
                    justifyContent="center"
                    gap={1}
                  >
                    <TypographyBodyBase
                      textAlign="start"
                      sx={{
                        color: themeColors.text.primary,
                      }}
                    >
                      Compila i dati per poter procedere con il tuo acquisto.
                      Tutti i campi sono obbligatori.
                    </TypographyBodyBase>
                    <Stack direction="row" mt={2} gap={2}>
                      <LabelledRadio
                        label="Privato"
                        name="businessType"
                        value={BUSINESS_TYPE_PRIVATE}
                        onChange={handleChange}
                        checked={values.businessType == BUSINESS_TYPE_PRIVATE}
                        icon={
                          <Box
                            component={"img"}
                            src={`${baseImageUrl}/ic-radio-unchecked.svg`}
                          />
                        }
                        checkedIcon={
                          <Box
                            component={"img"}
                            src={`${baseImageUrl}/ic-radio-checked.svg`}
                          />
                        }
                      />
                      <LabelledRadio
                        label="Azienda"
                        value={BUSINESS_TYPE_AGENCY}
                        name="businessType"
                        onChange={handleChange}
                        checked={values.businessType == BUSINESS_TYPE_AGENCY}
                        icon={
                          <Box
                            component={"img"}
                            src={`${baseImageUrl}/ic-radio-unchecked.svg`}
                          />
                        }
                        checkedIcon={
                          <Box
                            component={"img"}
                            src={`${baseImageUrl}/ic-radio-checked.svg`}
                          />
                        }
                      />
                    </Stack>
                    <Stack
                      direction="column"
                      sx={{
                        width: "100%",
                        marginTop: "24px",
                      }}
                    >
                      {values.businessType === BUSINESS_TYPE_AGENCY && (
                        <>
                          <LabelledInputMaterial
                            label="Ragione Sociale"
                            type="text"
                            name="businessName"
                            value={values.businessName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.businessName && errors.businessName && (
                            <FormHelperText sx={{ margin: "0px" }} error>
                              {errors.businessName}
                            </FormHelperText>
                          )}
                          <LabelledInputMaterial
                            label="Partita IVA"
                            type="text"
                            name="vatNumber"
                            value={values.vatNumber}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.vatNumber && errors.vatNumber && (
                            <FormHelperText sx={{ margin: "0px" }} error>
                              {errors.vatNumber}
                            </FormHelperText>
                          )}
                        </>
                      )}

                      <LabelledInputMaterial
                        label="Nome"
                        type="text"
                        name="firstName"
                        required={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        error={
                          touched.firstName && errors.firstName ? true : false
                        }
                      />
                      {touched.firstName && errors.firstName && (
                        <FormHelperText sx={{ margin: "0px" }} error>
                          {errors.firstName}
                        </FormHelperText>
                      )}

                      <LabelledInputMaterial
                        label="Cognome"
                        type="text"
                        required={true}
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        error={
                          touched.lastName && errors.lastName ? true : false
                        }
                      />
                      {touched.lastName && errors.lastName && (
                        <FormHelperText sx={{ margin: "0px" }} error>
                          {errors.lastName}
                        </FormHelperText>
                      )}
                      <LabelledInputMaterial
                        label="Codice fiscale"
                        name="taxId"
                        type="text"
                        required={false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.taxId}
                        error={touched.taxId && errors.taxId ? true : false}
                      />
                      {touched.taxId && errors.taxId && (
                        <FormHelperText sx={{ margin: "0px" }} error>
                          {errors.taxId}
                        </FormHelperText>
                      )}

                      <Typography
                        fontFamily={themeFonts.regular}
                        fontSize={"12px"}
                        fontWeight={400}
                        lineHeight={"15.6px"}
                        letterSpacing={"1px"}
                        sx={{
                          margin: "0px 0px 20px 0px",
                          textAlign: "left",
                          color: themeColors.common.secondary,
                        }}
                      >
                        Obbligatorio se hai bisogno della fattura.
                      </Typography>

                      <LabelledInputMaterial
                        label="SDI o PEC"
                        name="sdiopec"
                        type="text"
                        required={false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.sdiopec}
                        error={touched.sdiopec && errors.sdiopec ? true : false}
                      />
                      {touched.sdiopec && errors.sdiopec && (
                        <FormHelperText sx={{ margin: "0px" }} error>
                          {errors.sdiopec}
                        </FormHelperText>
                      )}

                      <Typography
                        fontFamily={themeFonts.regular}
                        fontSize={"12px"}
                        fontWeight={400}
                        lineHeight={"15.6px"}
                        letterSpacing={"1px"}
                        sx={{
                          margin: "0px 0px 20px 0px",
                          textAlign: "left",
                          color: themeColors.common.secondary,
                        }}
                      >
                        Obbligatorio se hai bisogno della fattura.
                      </Typography>

                      <LabelledInputMaterial
                        label="Email"
                        required={true}
                        name="email"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={touched.email && errors.email ? true : false}
                      />
                      {touched.email && errors.email && (
                        <FormHelperText sx={{ margin: "0px" }} error>
                          {errors.email}
                        </FormHelperText>
                      )}

                      <Typography
                        fontFamily={themeFonts.regular}
                        fontSize={"12px"}
                        fontWeight={400}
                        lineHeight={"15.6px"}
                        letterSpacing={"1px"}
                        sx={{
                          margin: "0px 0px 20px 0px",
                          textAlign: "left",
                          color: themeColors.common.secondary,
                        }}
                      >
                        Utilizza la stessa mail associata al tuo account
                        Nespresso
                      </Typography>

                      <LabelledInputMaterial
                        label="Telefono"
                        required={true}
                        name="telephone"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.telephone}
                        error={
                          touched.telephone && errors.telephone ? true : false
                        }
                      />
                      {touched.telephone && errors.telephone && (
                        <FormHelperText sx={{ margin: "0px" }} error>
                          {errors.telephone}
                        </FormHelperText>
                      )}
                      <Stack direction="row" gap={1}>
                        <Box
                          sx={{
                            width: "66%",
                          }}
                        >
                          <LabelledInputMaterial
                            label="Indirizzo"
                            name="address"
                            required={true}
                            type="text"
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.address && errors.address ? true : false
                            }
                          />
                          {touched.address && errors.address && (
                            <FormHelperText sx={{ margin: "0px" }} error>
                              {errors.address}
                            </FormHelperText>
                          )}
                        </Box>
                        <Box
                          sx={{
                            width: "33%",
                          }}
                        >
                          <LabelledInputMaterial
                            label="Num. civico"
                            name="civicNumber"
                            required={true}
                            type="number"
                            value={values.civicNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.civicNumber && errors.civicNumber
                                ? true
                                : false
                            }
                          />
                          {touched.civicNumber && errors.civicNumber && (
                            <FormHelperText sx={{ margin: "0px" }} error>
                              {errors.civicNumber}
                            </FormHelperText>
                          )}
                        </Box>
                      </Stack>
                      <Stack direction="row" gap={1}>
                        <Box
                          sx={{
                            width: "33%",
                          }}
                        >
                          <LabelledInputMaterial
                            label="CAP"
                            required={true}
                            name="capital"
                            type="number"
                            value={values.capital}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.capital && errors.capital ? true : false
                            }
                          />
                          {touched.capital && errors.capital && (
                            <FormHelperText sx={{ margin: "0px" }} error>
                              {errors.capital}
                            </FormHelperText>
                          )}
                        </Box>
                        <Box
                          sx={{
                            width: "66%",
                          }}
                        >
                          <LabelledInputMaterial
                            label="Città"
                            name="city"
                            required={true}
                            type="text"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.city && errors.city ? true : false}
                          />
                          {touched.city && errors.city && (
                            <FormHelperText sx={{ margin: "0px" }} error>
                              {errors.city}
                            </FormHelperText>
                          )}
                        </Box>
                      </Stack>
                      <Stack gap={1} width="100%">
                        <TypographyBodySmall width="fit-content">
                          Provincia{" "}
                          <Box
                            component={"span"}
                            sx={{ color: themeColors.common.error }}
                          >
                            *
                          </Box>
                        </TypographyBodySmall>

                        <NespressoSelect
                          regionId={regionId}
                          name={"province"}
                          selectedValue={values.province}
                          setFieldValue={setFieldValue}
                          onBlur={() => setFieldTouched("province", true)}
                        />
                        {touched.province && errors.province && (
                          <FormHelperText sx={{ margin: "0px" }} error>
                            {errors.province}
                          </FormHelperText>
                        )}
                      </Stack>
                      <Stack gap={1} width="100%" mt={2}>
                        <TypographyBodySmall width="fit-content">
                          Nazione{" "}
                          <Box
                            component={"span"}
                            sx={{ color: themeColors.common.error }}
                          >
                            *
                          </Box>
                        </TypographyBodySmall>
                        <NespressoNazioneSelect
                          name={"country"}
                          selectedValue={values.country}
                          setFieldValue={setFieldValue}
                          onBlur={() => setFieldTouched("country", true)}
                        />
                        {touched.country && errors.country && (
                          <FormHelperText sx={{ margin: "0px" }} error>
                            {errors.country}
                          </FormHelperText>
                        )}
                      </Stack>

                      <Checkbox
                        label={
                          <div>
                            Ho letto e accetto l’informativa sulla privacy{" "}
                            <Box
                              component="span"
                              sx={{ color: themeColors.common.error }}
                            >
                              *
                            </Box>
                          </div>
                        }
                        id="privacyPolicy"
                        name="privacyPolicy"
                        onChange={(e) => {
                          setFieldValue("privacyPolicy", e.target.checked);
                        }}
                      />
                      {errors.privacyPolicy &&
                        values.privacyPolicy == false && (
                          <FormHelperText sx={{ margin: "0px" }} error>
                            {errors.privacyPolicy}
                          </FormHelperText>
                        )}
                      <Divider />
                      <Checkbox
                        label={
                          <div>
                            <Typography
                              textAlign={"start"}
                              fontFamily={themeFonts.regular}
                              fontSize={"14px"}
                              fontWeight={400}
                              lineHeight={"18.2px"}
                              letterSpacing={"1px"}
                            >
                              Desidero ricevere le mie fatture in PDF
                            </Typography>
                            <Typography
                              color={themeColors.text.primary}
                              textAlign={"start"}
                              fontFamily={themeFonts.regular}
                              fontSize={"12px"}
                              fontWeight={400}
                              lineHeight={"15.6px"}
                              letterSpacing={"1px"}
                            >
                              Spuntando questa casella, ci consenti di inviare
                              la fattura del tuo ordine in formato PDF al tuo
                              indirizzo e-mail.{" "}
                              <Typography
                                component="span"
                                fontFamily={themeFonts.regular}
                                fontSize={"12px"}
                                fontWeight={700}
                                lineHeight={"15.6px"}
                                letterSpacing={"1px"}
                              >
                                {" "}
                                Ricordati di compilare il campo Codice fiscale.
                              </Typography>
                            </Typography>
                          </div>
                        }
                        id="receiveInvoice"
                        name="receiveInvoice"
                        onChange={(e) => {
                          setFieldValue("receiveInvoice", e.target.checked);
                        }}
                      />
                      <Divider />

                      <Box
                        component={"div"}
                        sx={{
                          background: themeColors.common.pillWhite,
                          margin: "16px 0px 16px 0px",
                          padding: "16px",
                        }}
                      >
                        <Typography
                          fontFamily={themeFonts.bold}
                          fontSize={"16px"}
                          fontWeight={700}
                          lineHeight={"20.8px"}
                          letterSpacing={"1px"}
                          textAlign={"start"}
                        >
                          Teniamoci in contatto!
                        </Typography>
                        <TypographyBodySmall
                          sx={{ textAlign: "start", paddingTop: "16px" }}
                        >
                          Ci piacerebbe inviarti le nostre offerte esclusive,
                          che includono coupon sconto e omaggi, e le
                          informazioni su tutti i servizi della Nespresso
                          family*
                        </TypographyBodySmall>
                        <TypographyBodySmall
                          sx={{
                            textAlign: "start",
                            padding: "16px 0px 16px 0px",
                          }}
                        >
                          Scegli come riceverle:
                        </TypographyBodySmall>

                        <Box component={"div"} sx={{ paddingBottom: "24px" }}>
                          <LabelledSwitch
                            label="Seleziona tutte"
                            onChange={(e) => {
                              setFieldValue("selectAll", e.target.checked);
                              setFieldValue("emailCheck", e.target.checked);
                              setFieldValue("phone", e.target.checked);
                              setFieldValue("sms", e.target.checked);
                              setFieldValue("postal", e.target.checked);
                            }}
                            name="selectAll"
                            value="selectAll"
                            checked={values.selectAll}
                          />
                        </Box>

                        <LabelledSwitch
                          label="E-mail"
                          onChange={handleChange}
                          name="emailCheck"
                          value="emailCheck"
                          checked={values.emailCheck}
                          content="Il tuo consenso ci permette di inviarti comunicazioni, novità e offerte via e-mail"
                        />

                        <LabelledSwitch
                          label="Telefono"
                          onChange={handleChange}
                          name="phone"
                          value="phone"
                          checked={values.phone}
                          content="Ti contatteremo per telefono solo per motivi legati all’assistenza clienti"
                        />
                        <LabelledSwitch
                          label="Posta"
                          value="postal"
                          onChange={handleChange}
                          name="postal"
                          checked={values.postal}
                          content="Il tuo consenso ci permetterà di comunicarti le ultime novità e offerte tramite posta tradizionale."
                        />
                        <LabelledSwitch
                          label="SMS"
                          value="sms"
                          onChange={handleChange}
                          name="sms"
                          checked={values.sms}
                          content="Il tuo consenso ci permette di inviarti comunicazioni, novità e offerte via e-mail."
                        />

                        <Typography
                          textAlign={"start"}
                          fontFamily={themeFonts.regular}
                          fontSize={"12px"}
                          fontWeight={400}
                          lineHeight={"15.6px"}
                          letterSpacing={"1px"}
                        >
                          * Nestlé Nespresso S.A., Nespresso Italiana S.p.A. e
                          Nestlé Italiana S.p.A
                        </Typography>
                      </Box>

                      {values.businessType === "azienda" && (
                        <Box
                          component={"div"}
                          sx={{
                            background: themeColors.common.pillWhite,
                            margin: "0px 0px 16px 0px",
                            padding: "16px",
                            backgroundColor: "rgba(217, 134, 0, 0.05);",
                          }}
                        >
                          <Typography
                            fontFamily={themeFonts.regular}
                            fontSize={"14px"}
                            fontWeight={400}
                            lineHeight={"18.2px"}
                            letterSpacing={"1px"}
                            sx={{ color: themeColors.common.note }}
                          >
                            Ti ricordiamo che dopo la conferma dell'ordine
                            riceverai la relativa fattura all’indirizzo email
                            che hai inserito.
                          </Typography>
                        </Box>
                      )}

                      <ButtonSquare
                        disabled={
                          Object.keys(errors).length !== 0 || !values.firstName
                        }
                        type="submit"
                      >
                        {submitLoading ? (
                          <CircularProgress
                            size={"25px"}
                            sx={{ color: "#fff" }}
                          />
                        ) : (
                          "Procedi al pagamento"
                        )}
                      </ButtonSquare>
                    </Stack>
                  </Stack>
                </Card>
              </Container>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default Checkout;
