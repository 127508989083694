import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { notification } from "antd";
import {
  Stack,
  Divider,
  styled,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import "../assets/Css/mainOriginalList.css";
import "../assets/Css/mainOriginalListRes.css";
import { CenterHeading } from "./Checkout";
import TypographyBodyBase from "../components/TypographyBodyBase";
import H5 from "../components/H5";
import H6 from "../components/H6";
import TypographyBodySmall from "../components/TypographyBodySmall";
import { themeColors } from "../Constant/themeColor";
import Card from "../components/Card";
import Container from "../components/Container";
import { ButtonSquare, ButtonSquareSecondary } from "../components/Button";
import { accessToken } from "../Constant/token";
import axios from "axios";
import config from "../config";
import { themeFonts } from "../Constant/themeFont";
import { baseImageUrl } from "../Constant/constData";

const StyledSpan = styled("div")({
  fontWeight: 700,
});

const ThankYou = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const storeCode = searchParams.get("store");
  const token = searchParams.get("token");
  const payerID = searchParams.get("PayerID");
  const adyen = searchParams.get("adyen");
  const orderId = searchParams.get("orderId");
  const sessionId = searchParams.get("sessionId");
  const sessionResult = searchParams.get("sessionResult");

  const cartId = localStorage.getItem("cartId");

  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [orderNumber, setOrderNumber] = useState(orderId ? orderId : "");
  const [storeDetails, setStoreDetails] = useState();
  const [showToaster, setShowToaser] = useState(false);

  useEffect(() => {
    if (sessionId && sessionResult) {
      getSessionResponse();
    }
    if (cartId && !adyen) {
      setupPaymentMethod(cartId);
    }
    // if (orderId) {
    //   setShowToaser(true);
    // }
    getStoreDetails();
  }, []);

  const getStoreDetails = async () => {
    try {
      const url = config.placeholderApiUrl + `/${storeCode}/V1/storedetail/`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const { data } = await axios({
        method: "get",
        url,
        headers,
      });

      setStoreDetails(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getSessionResponse = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const url =
        config.placeholderApiUrl + `/${storeCode}/V1/adyen/session-status/`;

      const values = {
        session_id: sessionId,
        session_result: sessionResult,
        order_id: orderId,
      };

      const { data } = await axios({
        method: "post",
        url,
        headers,
        data: values,
      });

      const adyenStatus = JSON.parse(data);
      if (adyenStatus.status == "completed") {
        setShowToaser(true);
      } else {
        setOrderNumber("");
        setShowToaser(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      localStorage.removeItem("cartId");
    }
  };

  const setupPaymentMethod = async (id) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/paypalapi/setpaymentmethodoncart
    `;
    const values = {
      cart_id: id,
      payer_id: payerID,
      token: token,
      payment_method: "paypal_express",
    };
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios({
        method: "post",
        url,
        headers,
        data: values,
      });

      paypalPlaceHolder(id);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const paypalPlaceHolder = async (id) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/paypalapi/placeorder
    `;
    const values = {
      cart_id: id,
    };
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios({
        method: "post",
        url,
        headers,
        data: values,
      });

      setOrderNumber(response.data[0].order_number);
      localStorage.removeItem("cartId");
      setShowToaser(true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      localStorage.removeItem("cartId");
      setLoading(false);
    }
  };

  const openNotification = () => {
    const key = showToaster;

    if (showToaster) {
      api.success({
        description: (
          <Typography
            fontFamily={themeFonts.regular}
            fontSize={"16px"}
            fontWeight={700}
            lineHeight={"17px"}
            letterSpacing={"1px"}
            color={themeColors.common.white}
            textAlign={"left"}
            marginLeft={"10px"}
          >
            {orderNumber
              ? "Pagamento riuscito"
              : "Pagamento non riuscito, riprova"}
          </Typography>
        ),
        placement: "top",
        key,
        icon: (
          <Box
            sx={{ width: "30px", height: "30px" }}
            component={"img"}
            alt={"check Image"}
            src={`${baseImageUrl}/notificationCheck.png`}
          />
        ),
        closeIcon: null,
        className: "notification",
        style: {
          width: "343px",
          height: "auto",
          padding: "8px 16px 20px 16px",
          gap: "16px",
          background: orderNumber ? "#12A244" : "#FF0000",
          borderRadius: "4px",
        },
      });
    }
  };

  return (
    <>
      {openNotification()}
      {contextHolder}
      <Container>
        <CenterHeading
          sx={{
            marginBottom: "0px",
          }}
        >
          GRAZIE DELL’ACQUISTo!
        </CenterHeading>
        <TypographyBodyBase px={4} pb={3}>
          Le tue capsule saranno disponibili per il ritiro a breve.
        </TypographyBodyBase>
        <Card
          bgcolor="white"
          sx={{
            padding: "16px",
          }}
        >
          <Stack gap={1}>
            <H6>COME ritiro le mie capsule?</H6>
            <TypographyBodyBase mb={1} textAlign="start">
              {/* Per ritirare le tue capsule,{" "}
              <strong style={{ fontFamily: themeFonts.bold }}>
                mostra questa schermata
              </strong>{" "}
              o{" "}
              <strong style={{ fontFamily: themeFonts.bold }}>
                l’e-mail di conferma ordine
              </strong>{" "}
              che hai ricevuto nella tua casella e-mail al punto di ritiro. */}
              Rivolgiti a uno degli addetti vendita e mostra il codice
              dell’ordine che trovi in questa schermata o nell’e-mail di
              conferma ordine che hai ricevuto da Nespresso.
            </TypographyBodyBase>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <TypographyBodySmall
                sx={{
                  color: themeColors.text.primary,
                }}
                className="typography-label-small"
              >
                N° ordine
              </TypographyBodySmall>

              {loading ? (
                <CircularProgress
                  size={"15px"}
                  sx={{ color: themeColors.text.primary }}
                />
              ) : (
                <TypographyBodySmall
                  sx={{
                    color: themeColors.text.primary,
                  }}
                  className="typography-label-small"
                >
                  <strong style={{ fontFamily: themeFonts.bold }}>
                    # {orderNumber}
                  </strong>
                </TypographyBodySmall>
              )}
            </Stack>
            <Divider />
          </Stack>
        </Card>
        <Card
          bgcolor="white"
          sx={{
            padding: "16px",
            marginTop: "8px",
          }}
        >
          <Stack gap={1}>
            <H6 sx={{ color: themeColors.text.primary }}>
              DOVE RITIRO LE MIE CAPSULE?
            </H6>

            <TypographyBodyBase
              sx={{ color: themeColors.text.primary }}
              textAlign="start"
            >
              <StyledSpan>{storeDetails?.name}</StyledSpan>{" "}
              {storeDetails?.address}
            </TypographyBodyBase>

            <TypographyBodyBase
              sx={{ color: themeColors.text.primary }}
              textAlign="start"
            >
              {storeDetails?.additional_data}
            </TypographyBodyBase>
          </Stack>
        </Card>
        <Card
          bgcolor="white"
          sx={{
            padding: "16px",
            marginTop: "8px",
          }}
        >
          <Stack gap={1}>
            <H6>QUANDO POSSO RITIRARE LE MIE CAPSULE?</H6>
            <TypographyBodyBase
              sx={{
                color: themeColors.text.primary,
              }}
              textAlign="start"
            >
              Ricordati che puoi ritirare le capsule che hai ordinato fino a
              l’orario di chiusura del prossimo giorno lavorativo.
            </TypographyBodyBase>
          </Stack>
        </Card>
        <H5
          sx={{
            margin: "24px 0px 16px",
            color: themeColors.text.primary,
            textAlign: "center",
          }}
        >
          Hai bisogno di aiuto?
        </H5>
        <ButtonSquare
          startIcon={
            <img
              src={`${baseImageUrl}/ic-soc-whatsapp-white.svg`}
              width={20}
              height={20}
            />
          }
          sx={{
            width: "100%",
          }}
          onClick={() => {
            localStorage.removeItem("cartId");
            const url = `https://wa.me/2222222222?text=Ciao`;
            window.open(url, "_blank");
          }}
        >
          Contattaci su whatsapp
        </ButtonSquare>
        <ButtonSquareSecondary
          onClick={() => {
            localStorage.removeItem("cartId");
            navigate(`/faq?store=${storeCode}`);
          }}
          sx={{
            width: "100%",
            marginTop: "8px",
          }}
        >
          domande frequenti
        </ButtonSquareSecondary>
      </Container>
    </>
  );
};

export default ThankYou;
